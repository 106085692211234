<!-- nav -->
<nav class="navbar navbar-expand navbar-dark bg-dark" *ngIf="user">
    <div class="navbar-nav">
        <a class="nav-item nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Home</a>
        <a class="nav-item nav-link" routerLink="/users" routerLinkActive="active">Users</a>
        <a class="nav-item nav-link" (click)="logout()">Logout</a>
    </div>
</nav>

<!-- main app container -->
<div class="app-container" [ngClass]="{ 'bg-light': user }">
    <alert></alert>
    <router-outlet></router-outlet>
</div>
