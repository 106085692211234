export class User {
      localId: string;
      email: string;
      firstName: string;
      lastName: string;
      //passwordHash: string;
      //emailVerified: boolean;
      //passwordUpdatedAt: string;
      //providerUserInfo: string[];  
      //validSince: string;
      //lastLoginAt: string;
      //createdAt: string;
      //lastRefreshAt: string;
      token: string;
}
