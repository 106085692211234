import { Component } from '@angular/core';

import { User } from '@app/_models';
import { AccountService } from '@app/_services';
import { AngularFireStorage } from '@angular/fire/storage';
import { Router, ActivatedRoute } from '@angular/router';

@Component({ templateUrl: 'home.component.html' })
export class HomeComponent {
    user: User;
    gameLink: string;

    constructor(private accountService: AccountService, private afStorage: AngularFireStorage,
     route:ActivatedRoute) {
        this.user = this.accountService.userValue;

        route.params.subscribe(val => {
        
       let gameRef = this.afStorage.ref('BoogysAdventureInstaller.exe');
       // Get the download URL
        gameRef.getDownloadURL()
        .subscribe((url) => {
           this.gameLink = url;
           console.log(url);
        })
        });
    
    }

    
}
