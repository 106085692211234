import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { User } from '@app/_models';

import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private afs: AngularFirestore,   // Inject Firestore service
        private afAuth: AngularFireAuth, // Inject Firebase auth service
        private ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
        this.user = this.userSubject.asObservable();

    }

    public get userValue(): User {
        return this.userSubject.value;
    }

    login(username, password) {
        return this.http.post<User>(`${environment.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('user', JSON.stringify(user));
                this.userSubject.next(user);
                return user;
            }));
    }

    logout() {
        // remove user from local storage and set current user to null
        localStorage.removeItem('user');
        this.userSubject.next(null);
        this.router.navigate(['/account/login']);
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }


    SignUp(email, password, route) {

      return this.afAuth.createUserWithEmailAndPassword(email, password);

    }






  SignIn(email, password) {
    console.log("SIGNING IN WITH FIREBASE");
        let loading = false;

    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {

         var docRef = this.afs.collection("users").doc(result.user.uid);

         docRef.get().subscribe((doc) => {
          if (doc.exists) {

         var firstname = doc.get("firstname");
         var lastname = doc.get("lastname");

         let user = {"email":result.user.email, "localId":result.user.uid, "token":result.user.refreshToken,
         "firstName": firstname, "lastName": lastname};
         localStorage.setItem('user', JSON.stringify(user));
         this.userSubject.next(user);
         this.router.navigate(['home']);
           // console.log("Document data:", doc.data());
          } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          }
        });


        

      });


  }


 

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id, params) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params)
            .pipe(map(x => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue.localId) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    localStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`)
            .pipe(map(x => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue.localId) {
                    this.logout();
                }
                return x;
            }));
    }
}
