<div class="p-4">
    <div class="container">
        <h1>Hi {{user.firstName}}!</h1>
        <!--<p><a routerLink="/users">Manage Users</a></p>-->

        <div *ngIf="gameLink">
            <a [href]="gameLink"> Try our game!</a>
        </div>

    </div>
</div>
